@font-face {
  font-family: "Padauk";
  src: url("../src/assets/fonts/Padauk-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Padauk";
  src: url("../src/assets/fonts/Padauk-Bold.ttf") format('truetype');
  font-weight: bold;
}

*{
  font-family: Padauk;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


:root {
  --bg-primary-color: rgb(50, 205, 158, 0.3);
  --primary-color: #32CD9E;
  --primary-light-color: #e0f3ed;
  --bg-warning-color: rgb(233, 153, 68, 0.3);
  --warning-color: #E99842;
  --bg-danger-color: rgb(239, 35, 60, 0.3);
  --danger-color: #EF233C;
}

/* custom primary button */
.primary_button{
  background-color: var(--bg-primary-color);
  color: var(--primary-color);
  font-weight: bold;
}

.primary_button:active{
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.primary_button:hover{
  background-color: var(--bg-primary-color);
  color: var(--primary-color);
  font-weight: bold;
}

/* custom primary outline button */
.primary_outline_button{
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.primary_outline_button:active{
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.primary_outline_button:hover{
  background-color: var(--bg-primary-color);
  color: var(--primary-color);
}

/* custom warning button */
.warning_button{
  background-color: var(--bg-warning-color);
  color: var(--warning-color);
  font-weight: bold;
}

.warning_button:active{
  border-color: var(--warning-color) !important;
  color: var(--warning-color) !important;
}

.warning_button:hover{
  background-color: var(--bg-warning-color);
  color: var(--warning-color);
  font-weight: bold;
}

/* custom danger button */
.danger_button{
  background-color: var(--bg-danger-color);
  color: var(--danger-color);
  font-weight: bold;
}

.danger_button:active{
  border-color: var(--danger-color) !important;
  color: var(--danger-color) !important;
}

.danger_button:hover{
  background-color: var(--bg-danger-color) !important;
  color: var(--danger-color);
  font-weight: bold;
}

/* custom danger outline button */
.danger_outline_button{
  color: var(--danger-color);
  border: 1px solid var(--danger-color);
}

.danger_outline_button:active{
  border-color: var(--danger-color) !important;
  color: var(--danger-color) !important;
}

.danger_outline_button:hover{
  background-color: var(--bg-danger-color) !important;
  color: var(--danger-color);
  font-weight: bold;
}

.nav-item:hover{
  color: var(--primary-color) !important;
  transition: .3s all;
}
/* 
.two_color_background {
  background-image: -webkit-linear-gradient(90deg, transparent 70%, var(--primary-color) 30%);
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* svg{
  font-size: 18px !important;
}

button p svg{
  font-size: 40px !important;
} */

table th,td{
  vertical-align: middle !important;
}

.search .wrapper:first-child {
  border-radius: 0px;
}

.contactLi:hover{
  background-color: rgb(50, 205, 158, 0.2);
  cursor: pointer;
  transition: all .3s;
}

.brandLogo{
  background-image: url('./assets//img/kooo.png');
  width: 150px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}

.table-responsive{
  max-height: 300px !important;
}

.table-responsive thead{
 position: sticky;
 top: 0;
}


@media (max-width: 820px) {
  .totalBox h5{
    font-size: 16px !important;
  }
  .creditTotal h5, .creditTotal h4{
    font-size: 18px !important;
  }
}

@media (max-width: 1000px) {
  td{
    padding: 15px 5px !important;
  }
}